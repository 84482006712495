<template>
  <div>
    <StoreSettingsHeader :titleBarActions="titleBarActions"/>
    <div class="col-12 table-number-container" ref="tableNumbersContainer" :style="{height: remainingHeight}">
      <RecycleScroller class="scroller" :items="tableNumbers" :itemSize="41" key-field="locationID">
        <template slot-scope="{item}">
          <TableNumberRow :tableNumber="item" :editing="editing" @input="handleToggle" />
        </template>
      </RecycleScroller>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StoreSettingsHeader from "../components/store_settings_header.vue";
import TableNumberRow from "components/table_number_row.vue";
import verge from "verge";
import { localToGlobal } from "helpers";
import clone from "clone";
import AccessMixin from "mixins/access_helper.js";
import StoreSettingsMixin from "../mixins/store_settings.js";

export default {
    name: "StoreSettingsTableNumbers",
    components: {
        StoreSettingsHeader,
        TableNumberRow
    },
    mixins: [AccessMixin, StoreSettingsMixin],

    data() {
        return {
            isMounted: false,
            bottomPadding: 0,
            tableNumbers: [],
            editing: false
        };
    },
    computed: {
        titleBarActions() {
            const edit = {
                type: "edit",
                display: "Edit",
                run: () => {
                    this.editing = true;
                }
            };

            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: () => {
                    this.editing = false;
                    this.tableNumbers = clone(this.storeTableNumbers);
                }
            };

            const save = {
                type: "proceed",
                display: "Save",
                run: () => {
                    this.saveTableNumbers();
                }
            };

            const allOn = {
                type: "green",
                noIcon: true,
                display: "Turn All Tables Online",
                run: () => {
                    this.tableNumbers = this.tableNumbers.map(el => {
                        return {
                            ...el,
                            bTableOnline: true
                        };
                    });
                }
            };

            const allOff = {
                type: "red",
                noIcon: true,
                display: "Turn All Tables Offline",
                run: () => {
                    this.tableNumbers = this.tableNumbers.map(el => {
                        return {
                            ...el,
                            bTableOnline: false
                        };
                    });
                }
            };
            if (this.editing) {
                return [allOn, allOff, save, cancel];
            } else if (this.canEdit) {
                return [edit];
            } else {
                return [];
            }
        },
        remainingHeight() {
            if (!this.isMounted) return `${verge.viewportH()}px`;
            return `${verge.viewportH() -
        localToGlobal(this.$refs.tableNumbersContainer).top -
        this.bottomPadding}px`;
        },
        storeTableNumbers() {
            if (this.env == "stage" && this.tableNumbersStage != undefined) {
                return this.tableNumbersStage;
            } else if (this.env == "prod" && this.tableNumbersProd != undefined) {
                return this.tableNumbersProd;
            }
            return []
        },
        ...mapGetters(["products", "store"]),
        ...mapGetters("SettingsStore", ["tableNumbersStage", "tableNumbersProd"])
    },
    watch: {
        store() {
            this.resolvePageTitle();
        }
    },
    created() {
        this.resolvePageTitle();
        const { store } = this.$route.params;
        this.getTableNumbers({
            waypointID: store,
            env: this.env
        }).then(tableNumbers => {
            if (tableNumbers == undefined) return
            this.tableNumbers = clone(tableNumbers);
        });
        if (this.storeTableNumbers != undefined) {
            this.tableNumbers = clone(this.storeTableNumbers)
        }
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Table Numbers`;
            }
        },
        handleToggle(obj) {
            const index = this.tableNumbers.findIndex(
                el => el.locationID == obj.locationID
            );
            var cloned = clone(this.tableNumbers[index]);
            cloned.bTableOnline = obj.value;
            this.tableNumbers.splice(index, 1, cloned);
        },
        saveTableNumbers() {
            const original = clone(this.storeTableNumbers);
            const { store } = this.$route.params;

            var diff = [];
            var allValuesSame = true;
            var bTableOnline = undefined;

            this.tableNumbers.forEach((el, i) => {
                if (el.bTableOnline != original[i].bTableOnline) diff.push(el);
                if (bTableOnline == undefined) {
                    bTableOnline = el.bTableOnline;
                } else {
                    if (bTableOnline != el.bTableOnline) {
                        allValuesSame = false;
                    }
                }
            });
            this.editing = false;

            if (allValuesSame) {
                if (bTableOnline) {
                    this.setAllTablesOnline({
                        waypointID: store,
                        env: this.env
                    });
                } else {
                    this.setAllTablesOffline({
                        waypointID: store,
                        env: this.env
                    });
                }
            } else if (diff.length)
                this.saveTableNumberChanges({
                    waypointID: store,
                    tableNumbers: diff,
                    env: this.env
                });
        },
        ...mapActions("SettingsStore", [
            "getTableNumbers",
            "saveTableNumberChanges",
            "setAllTablesOnline",
            "setAllTablesOffline"
        ])
    },
    watch: {
        storeTableNumbers() {
            this.tableNumbers = clone(this.storeTableNumbers);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";

.table-number-container {
  padding-left: 0px;
}

.scroller {
  height: 100%;
  overflow-y: auto;
}
</style>
