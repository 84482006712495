var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StoreSettingsHeader", {
        attrs: { titleBarActions: _vm.titleBarActions },
      }),
      _c(
        "div",
        {
          ref: "tableNumbersContainer",
          staticClass: "col-12 table-number-container",
          style: { height: _vm.remainingHeight },
        },
        [
          _c("RecycleScroller", {
            staticClass: "scroller",
            attrs: {
              items: _vm.tableNumbers,
              itemSize: 41,
              "key-field": "locationID",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _c("TableNumberRow", {
                      attrs: { tableNumber: item, editing: _vm.editing },
                      on: { input: _vm.handleToggle },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }