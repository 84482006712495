var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LineItem", { staticClass: "height-xs--7" }, [
    _c(
      "div",
      {
        staticClass:
          "table_number_row__line row no-gutters height-xs--inherit align-items-center",
      },
      [
        _c("div", { staticClass: "table_number_row__head col-2" }, [
          _c("div", { staticClass: "table_number_row__icon" }),
          _c("div", { staticClass: "table_number_row__title" }, [
            _vm._v(" Table: " + _vm._s(this.tableNumber.locationName) + " "),
          ]),
        ]),
        _c("div", { staticClass: "table_number_row__head col-2" }, [
          _c("div", { staticClass: "table_number_row__title" }, [
            _vm._v(
              " Revenue Center: " + _vm._s(this.tableNumber.revenueCenter) + " "
            ),
          ]),
        ]),
        _vm.editing
          ? _c(
              "div",
              { staticClass: "table_number_row__head col-2" },
              [
                _c("Toggle", {
                  attrs: {
                    value: _vm.tableNumber.bTableOnline,
                    label: _vm.labelString,
                    labelColor: _vm.labelColor,
                    slider: "true",
                    labelWidth: "75px",
                  },
                  on: { input: _vm.handleToggle },
                }),
              ],
              1
            )
          : _c("div", [
              _c(
                "span",
                { staticClass: "label", style: { color: _vm.labelColor } },
                [_vm._v(" " + _vm._s(_vm.labelString) + " ")]
              ),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }